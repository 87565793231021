import _ from "lodash";
import * as Actions from "../actions/productReview";

export const initialState = {
  productReviewLastUpdatedDate: {},
  productReviewLastUpdatedDateFetching: false,
  productReviewLastUpdatedDateError: {},
  productReviews: [],
  productReviewsNext: "",
  fetchingProductReviews: false,
  fetchingProductReviewsNext: false,
  currentFilterStatus: 1,
  fetchingProductReviewsError: {},
  selectedProduct: {},
  fetchingSelectedProductDetails: false,
  selectedProductError: {},
  updatingCurrentProductDetails: false,
  updatingProductStatus: false,
  productReviewSearch: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_PRODUCT_REVIEW_LAST_UPDATED_DATE:
      return {
        ...state,
        productReviewLastUpdatedDateFetching: true,
      };
    case Actions.SET_PRODUCT_REVIEW_LAST_UPDATED_DATE:
      return {
        ...state,
        productReviewLastUpdatedDate: action.response,
        productReviewLastUpdatedDateFetching: false,
      };
    case Actions.SET_CURRENT_FILTER_STATUS:
      return {
        ...state,
        currentFilterStatus: _.get(action, "payload", 1),
      };
    case Actions.GET_PRODUCT_REVIEWS:
      return {
        ...state,
        fetchingProductReviews: true,
        fetchingProductReviewsNext: false,
        productReviews: [],
      };
    case Actions.GET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        productReviews: _.get(action, "result.results", []),
        productReviewsNext: _.get(action, "result.next", ""),
        fetchingProductReviewsNext: false,
        fetchingProductReviews: false,
      };
    case Actions.GET_PRODUCT_REVIEWS_ERROR:
      return {
        ...state,
        productReviews: [],
        fetchingProductReviewsNext: false,
        fetchingProductReviews: false,
        fetchingProductReviewsError: _.get(action, "error", {}),
      };
    case Actions.GET_PRODUCT_REVIEWS_NEXT:
      return {
        ...state,
        fetchingProductReviewsNext: true,
      };
    case Actions.GET_PRODUCT_NEXT_REVIEWS_SUCCESS:
      return {
        ...state,
        productReviews: [...state.productReviews, ..._.get(action, "result.results", [])],
        productReviewsNext: _.get(action, "result.next", ""),
        fetchingProductReviewsNext: false,
      };
    case Actions.GET_PRODUCT_NEXT_REVIEWS_ERROR:
      return {
        ...state,
        productReviews: [],
        fetchingProductReviewsNext: false,
        fetchingProductReviews: false,
        fetchingProductReviewsError: _.get(action, "error", {}),
      };
    case Actions.UPDATE_PRODUCT_REVIEW_STATUS:
      return {
        ...state,
        productReviews: _.reject(state.productReviews, ["id", action.payload.id]),
      };
    case Actions.GET_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        fetchingSelectedProductDetails: true,
      };
    case Actions.GET_SELECTED_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingSelectedProductDetails: false,
        selectedProduct: _.get(action, "response", {}),
      };
    case Actions.GET_SELECTED_PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        fetchingSelectedProductDetails: false,
        selectedProductError: _.get(action, "error", {}),
      };
    case Actions.UPDATE_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        updatingCurrentProductDetails: true,
      };
    case Actions.UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        updatingCurrentProductDetails: false,
        updatingProductStatus: false,
      };
    case Actions.RESET_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        selectedProduct: {},
        updatingCurrentProductDetails: false,
        updatingProductStatus: false,
      };
    case Actions.PUBLISH_SELECTED_PRODUCT_DETAILS:
      return {
        ...state,
        updatingProductStatus: true,
      };
    case Actions.PUBLISH_SELECTED_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        updatingProductStatus: false,
      };
    case Actions.PRODUCT_REVIEW_SEARCH:
      return {
        ...state,
        productReviewSearch: action.searchValue,
      };
    case Actions.PRODUCT_REVIEW_SEARCH_CLEAR: {
      return {
        ...state,
        productReviewSearch: "",
      };
    }
    case Actions.UPDATE_SELECTED_PRODUCT_DETAILS_ERROR: {
      return {
        ...state,
        updatingCurrentProductDetails: false,
        updatingProductStatus: false,
      };
    }
    default:
      return state;
  }
};
