export const GET_PRODUCT_REVIEW_LAST_UPDATED_DATE = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_LAST_UPDATED_DATE";
export const SET_PRODUCT_REVIEW_LAST_UPDATED_DATE = "PRODUCT_REVIEW/SET_PRODUCT_REVIEW_LAST_UPDATED_DATE";
export const SET_PRODUCT_REVIEW_LAST_UPDATED_STATUS = "PRODUCT_REVIEW/SET_PRODUCT_REVIEW_LAST_UPDATED_STATUS";
export const SET_PRODUCT_REVIEW_LAST_UPDATED_ERROR = "PRODUCT_REVIEW/SET_PRODUCT_REVIEW_LAST_UPDATED_ERROR";
export const GET_PRODUCT_REVIEWS = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW";
export const SET_PRODUCT_REVIEWS = "PRODUCT_REVIEW/SET_PRODUCT_REVIEW";
export const GET_PRODUCT_REVIEWS_NEXT = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_NEXT";
export const SET_PRODUCT_REVIEWS_NEXT = "PRODUCT_REVIEW/SET_PRODUCT_REVIEW_NEXT";
export const SET_CURRENT_FILTER_STATUS = "PRODUCT_REVIEW/SET_CURRENT_FILTER_STATUS";
export const GET_PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_SUCCESS";
export const GET_PRODUCT_REVIEWS_ERROR = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_ERROR";
export const GET_PRODUCT_NEXT_REVIEWS_SUCCESS = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_NEXT_SUCCESS";
export const GET_PRODUCT_NEXT_REVIEWS_ERROR = "PRODUCT_REVIEW/GET_PRODUCT_REVIEW_NEXT_ERROR";
export const UPDATE_PRODUCT_REVIEW_STATUS = "PRODUCT_REVIEW/UPDATE_PRODUCT_REVIEW_STATUS";
export const GET_SELECTED_PRODUCT_DETAILS = "PRODUCT_REVIEW/GET_SELECTED_PRODUCT_DETAILS";
export const GET_SELECTED_PRODUCT_DETAILS_SUCCESS = "PRODUCT_REVIEW/GET_SELECTED_PRODUCT_DETAILS_SUCCESS";
export const GET_SELECTED_PRODUCT_DETAILS_ERROR = "PRODUCT_REVIEW/GET_SELECTED_PRODUCT_DETAILS_ERROR";
export const UPDATE_SELECTED_PRODUCT_DETAILS = "PRODUCT_REVIEW/UPDATE_SELECTED_PRODUCT_DETAILS";
export const UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS = "PRODUCT_REVIEW/UPDATE_SELECTED_PRODUCT_DETAILS_SUCCESS";
export const UPDATE_SELECTED_PRODUCT_DETAILS_ERROR = "PRODUCT_REVIEW/UPDATE_SELECTED_PRODUCT_DETAILS_ERROR";
export const RESET_SELECTED_PRODUCT_DETAILS = "PRODUCT_REVIEW/RESET_SELECTED_PRODUCT_DETAILS";
export const DO_UPDATE_PRODUCT_REVIEW_PHOTOS = "PRODUCT_REVIEW/DO_UPDATE_PRODUCT_REVIEW_PHOTOS";
export const DO_UPDATE_PRODUCT_REVIEW_PHOTOS_SUCCESS = "PRODUCT_REVIEW/DO_UPDATE_PRODUCT_REVIEW_PHOTOS_SUCCESS";
export const DO_UPDATE_PRODUCT_REVIEW_PHOTOS_ERROR = "PRODUCT_REVIEW/DO_UPDATE_PRODUCT_REVIEW_PHOTOS_SUCCESS";
export const PUBLISH_SELECTED_PRODUCT_DETAILS = "PRODUCT_REVIEW/PUBLISH_SELECTED_PRODUCT_DETAILS";
export const PUBLISH_SELECTED_PRODUCT_DETAILS_SUCCESS = "PRODUCT_REVIEW/PUBLISH_SELECTED_PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_SUCCESS = "PRODUCT_REVIEW/UPLOAD_PRODUCT_PHOTO_SUCCESS";
export const PRODUCT_REVIEW_UPLOAD_PRODUCT_PHOTO_ERROR = "PRODUCT_REVIEW/UPLOAD_PRODUCT_PHOTO_ERROR";
export const PRODUCT_REVIEW_SEARCH = "PRODUCT_REVIEW/SEARCH";
export const PRODUCT_REVIEW_SEARCH_CLEAR = "PRODUCT_REVIEW/SEARCH_CLEAR";

export const getProductReviewLastUpdatedDate = (payload) => {
  return {
    type: GET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
    payload,
  };
};

export const setProductReviewLastUpdatedDate = (payload) => {
  return {
    type: SET_PRODUCT_REVIEW_LAST_UPDATED_DATE,
    payload,
  };
};

export const getProductReviews = (payload) => {
  return {
    type: GET_PRODUCT_REVIEWS,
    payload,
  };
};

export const getProductReviewsNext = () => {
  return {
    type: GET_PRODUCT_REVIEWS_NEXT,
  };
};

export const setProductReviews = (payload) => {
  return {
    type: GET_PRODUCT_REVIEWS,
    payload,
  };
};

export const setProductReviewsNext = () => {
  return {
    type: GET_PRODUCT_REVIEWS_NEXT,
  };
};

export const setCurrentFilterStatus = (currentFilterStatus) => {
  return {
    type: SET_CURRENT_FILTER_STATUS,
    payload: currentFilterStatus,
  };
};

export const updateProductReviewStatus = (payload) => {
  return {
    type: UPDATE_PRODUCT_REVIEW_STATUS,
    payload,
  };
};

export const getSelectedProductDetails = (productId) => {
  return {
    type: GET_SELECTED_PRODUCT_DETAILS,
    productId,
  };
};

export const saveProductDetails = (payload) => {
  return {
    type: UPDATE_SELECTED_PRODUCT_DETAILS,
    payload,
  };
};

export const resetSelectedProducts = () => {
  return {
    type: RESET_SELECTED_PRODUCT_DETAILS,
  };
};

export const updateProductReviewProduct = (payload) => {
  return {
    type: PUBLISH_SELECTED_PRODUCT_DETAILS,
    payload,
  };
};

export const setProductReviewSearchValue = (searchValue) => {
  return {
    type: PRODUCT_REVIEW_SEARCH,
    searchValue,
  };
};

export const clearProductReviewSearchValue = () => {
  return {
    type: PRODUCT_REVIEW_SEARCH_CLEAR,
  };
};
